import React, { Component } from "react";
import _ from "lodash";
import { Link } from "gatsby";
import {Icon} from "semantic-ui-react";

class PostTags extends Component {
  render() {
    const { tags } = this.props;
    return (
      <div style={{padding:'0em 0em'}}>
        <p>
          <Link to='/'>
          <Icon name='tag' style={{color:'#111'}} />
          </Link>
        {tags &&
          tags.map(tag => (
            <Link
              key={tag}
              style={{ textDecoration: "none" }}
              to={`/tags/${_.kebabCase(tag)}`}
            >
              <span style={{color:'#444', fontFamily:'Georgia', fontSize:'17px'}}><u>{tag}</u> &nbsp; </span>
            </Link>
          ))}

          </p>
      </div>
    );
  }
}

export default PostTags;
