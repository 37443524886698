import React from "react";
import { Link } from "gatsby";
import { Grid, Header} from "semantic-ui-react";
import PostTags from "../PostTags/PostTags";



class PostListing extends React.Component {
  getPostList() {
    const postList = [];
    console.log(this.props);
    this.props.postEdges.forEach(postEdge => {
      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        cover: postEdge.node.frontmatter.cover,
        author: postEdge.node.frontmatter.author,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.fields.date,
        excerpt: postEdge.node.excerpt,
        summary: postEdge.node.frontmatter.summary,
        timeToRead: postEdge.node.timeToRead
      });
    });
    return postList;
  }
  getExcerpt(summary, excerpt)
    {
      if (summary) {
        return (
          summary
          )
      };
      return(excerpt);
    }

  render() {
    const postList = this.getPostList();
    return (   
        <Grid style={{ padding: '2em 0em' }} vertical container stackable verticalAlign='top'>
        {/* Your post list here. */
        postList.map(post => (
          <>
            <Grid.Row key={post}>
              <Grid.Column width={16}>
                <Header  as='h1' style={{ fontSize: '2em' }}>
                  {post.title}
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row key={post} stackable>
            <Grid.Column width={6}>
              <div>
                <p style={{color:'#000000'}}><img src={post.cover} alt={post.cover} width='100%' height='250px'/></p>
              </div>
              </Grid.Column>

            <Grid.Column width={10}>
              <div  style={{ fontSize:'19px', fontFamily:'Lato'}}>
                <p style={{color:'#000000', padding: '0em 0em', lineHeight:'1.6'}}>
                  {this.getExcerpt(post.summary, post.excerpt)}
                  </p>
              </div>
              <div style={{padding: '0.5em 0em', fontSize:'19px', fontFamily:'Lato'}}>
                  <Link to={post.path} key={post.title}>
                    Read More  
                  </Link>
                </div> 
                <div style={{padding: '0.25em 0em',  fontSize:'19px', fontFamily:'Lato'}}>
                  <PostTags tags={post.tags} />
                </div>        
                
              </Grid.Column>
            </Grid.Row>
            </>
        ))}
        </Grid>
    );
  }
}

export default PostListing;
